<template>
    <v-container fluid v-if="show" id="download">


        <v-row>
            <v-col>
                <h5 class="mt-5  primary--text">ڕاپۆرتی وەسڵی بەڕێز : {{ supplier.supplier_name }}</h5>
            </v-col>
            <v-col>
                <h5 class="mt-5  primary--text"> لە بەرواری : {{ $route.params.from }}</h5>
            </v-col>
            <v-col>
                <h5 class="mt-5  primary--text">بۆ بەرواری :{{ $route.params.to }}</h5>
            </v-col>
        </v-row>
        <br>
        <v-divider></v-divider>
        <br>
        <h4>قەرزی کۆن: {{ supplier.debt ? Number(supplier.debt.toFixed()).toLocaleString() + '$' : 0 }}</h4>

        <div v-for="(transaction, i) in transactions" :key="i">
            <div v-if="transaction.type == 'purchase' || transaction.type == 'return'" class="mb-15">
                <v-row>
                    <v-col>
                        <p>جۆری جووڵە : {{ transaction.purchase_type == 'p' ? 'کڕینی کاڵا' : 'گەڕاندنەوەی کاڵا' }}</p>
                    </v-col>
                    <v-col>
                        <p>ژمارەی وەصل: {{ transaction.purchase_id }}</p>
                    </v-col>
                    <v-col>
                        <p>جۆری پارەدان: {{ transaction.payment_type == 'd' ? 'قەرز' : 'نەقد' }}</p>
                    </v-col>
                    <v-col>
                        <p>بەروار: {{ new Date(transaction.date).toISOString().split("T")[0].split('-').reverse().join('-')
                        }}
                        </p>
                        <br>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <td class="primary--text text-center">#</td>
                                    <td class="primary--text text-center">ناوی کاڵا</td>
                                    <td class="primary--text text-center">بڕ بە یەکە</td>
                                    <td class="primary--text text-center">بڕ بە دانە</td>
                                    <td class="primary--text text-center">کۆی دانە</td>
                                    <td class="primary--text text-center">نرخی دانە</td>
                                    <td class="primary--text text-center">سەرجەم</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, i) in transaction.purchase_items" :key="i">
                                    <td class=" text-center">{{ i + 1 }}</td>
                                    <td class=" text-center">{{ item.item_name }}</td>
                                    <td class=" text-center">{{ item.qty_unit + `(${item.unit_name})` }}</td>
                                    <td class=" text-center">{{ item.qty_item }}</td>
                                    <td class=" text-center">{{ item.qty_item + (item.qty_unit * item.item_per_unit) }}</td>
                                    <td class=" text-center">${{ item.cost_price }}</td>
                                    <td class=" text-center">{{ '$' + Number(((item.cost_price * item.qty_unit *
                                        item.item_per_unit) + (item.qty_item *
                                            item.cost_price)).toFixed(2)).toLocaleString() }}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>

                                    <td colspan="2">تێبینی</td>
                                    <td colspan="2" class="primary white--text">{{ transaction.note }}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>

                                    <td colspan="2">سەرجەم</td>
                                    <td colspan="2" class="primary white--text">${{ Number((transaction.total +
                                        transaction.discount).toFixed(2)).toLocaleString() }}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>

                                    <td colspan="2">داشکاندن</td>
                                    <td colspan="2" class="primary white--text">
                                        ${{ Number(transaction.discount.toFixed(2)).toLocaleString() }}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>

                                    <td colspan="2">سەرجەم دوای داشکاندن</td>
                                    <td colspan="2" class="primary white--text">
                                        ${{ Number(transaction.total.toFixed(2)).toLocaleString() }}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td colspan="2">قەرزی کۆن</td>
                                    <td colspan="2" class="primary white--text">
                                        ${{ Number((transaction.previouse_debt).toFixed(2)).toLocaleString() }}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td colspan="2">کۆی قەرز</td>
                                    <td colspan="2" class="primary white--text">
                                        ${{ Number((transaction.current_debt).toFixed(2)).toLocaleString() }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>
                </v-row>
                <v-divider class="mt-5 black--text"></v-divider>

            </div>
            <div v-if="transaction.type == 'give_debt'">
                <p>جۆری جووڵە: گەڕاندنەوەی قەرز</p>
                <v-simple-table>
                    <thead>
                        <tr>
                            <td class="primary--text text-center">بەروار</td>
                            <td class="primary--text text-center">داشکاندن</td>
                            <td class="primary--text text-center">بڕی پارە</td>
                            <td class="primary--text text-center">تێبینی</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class=" text-center">{{ transaction.date.split("T")[0].split('-').reverse().join('-') }}
                            </td>
                            <td class=" text-center">${{ transaction.discount }}</td>
                            <td class=" text-center">${{ Number(transaction.amount.toFixed(2)).toLocaleString() }}</td>
                            <td class=" text-center">{{ transaction.note }}</td>
                        </tr>
                        <tr>
                            <td class=" text-center"></td>
                            <td class=" text-center">کۆی قەرز</td>
                            <td class=" text-center">${{ Number(transaction.current_debt).toFixed(2).toLocaleString() }}
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
                <v-divider></v-divider>
                <br>
            </div>
        </div>
        <div>
            <h2>کۆی قەرز: {{ supplier.total ? supplier.total.toLocaleString() + '$' : 0 }}</h2>
        </div>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            window: this.window,
            returns: [],
            transactions: [],
            supplier: {}
        }
    },
    async created() {
        const { data } = await this.axios.get('/report/supplier/' + this.$route.params.supplier_id + '/' + this.$route.params.from + '/' + this.$route.params.to)

        this.supplier = data.supplier
        this.transactions = data.transactions
        this.transactions.forEach(transaction => {
            transaction.date = transaction.date.split('T')[0]
        })

    },
    updated() {
        setTimeout(() => {
            window.print();
            this.$router.back()
        }, 200);
    },
    computed: {
        show() {
            return true
        },
    },


}
</script>

<style scoped>
tr td {
    border: 1px solid black;
    border-radius: 3%;
}
</style>